import {useSelector} from "react-redux";
import "./header.css";

const Header = (props) => {       

    const claimData = useSelector((state) => state.data);    
    const guiData = useSelector((state) => state.appgui);

    let name = "";    
    let anredeString = "";

    if (claimData.haftpflicht === "N") {
        if (claimData.claim.contractData.vn_vorname) {
            name = claimData.claim.contractData.vn_vorname;
        }
        if (claimData.claim.contractData.vn_nachname) {
            if (name.length > 0) {
                name = name +" ";
            }
            name = name + claimData.claim.contractData.vn_nachname;
        }
        if (claimData.claim.contractData.vn_anrede != null) {
            anredeString = claimData.claim.contractData.vn_anrede+" ";
        }
    } else {
        name = "";

        if (claimData.claim.contractData.as_vorname) {
            name = claimData.claim.contractData.as_vorname;
        }
        if (claimData.claim.contractData.as_nachname) {
            if (name.length > 0) {
                name = name +" ";
            }
            name = name + claimData.claim.contractData.as_nachname;
        }
        if (claimData.claim.contractData.as_anrede != null) {
            anredeString = claimData.claim.contractData.as_anrede+" ";
        }
    }
    
    let anrede = `Guten Tag ${anredeString}${name}`;        
        
    let assignementDate = claimData.claim.contractData.timestamp_auftrag;
    try {
        const myDate = Date.parse(claimData.claim.contractData.timestamp_auftrag);
        assignementDate = new Intl.DateTimeFormat("de-DE", {
            year: "numeric",
            month: "long",
            day: "2-digit"
        }).format(myDate);
    } catch(e) {
        console.log("Header(): error while converting date: ", claimData.claim.contractData.timestamp_auftrag);
    }

    let photoTexts = (
        <ul>
            <li><a href="#box_1" className="linksToGridBox"><span className="boldFont">Video rund um das Fahrzeug (max. 1min)</span></a></li>
            <li><a href="#box_2" className="linksToGridBox">Fahrzeugschein von vorne</a></li>
            <li><a href="#box_3" className="linksToGridBox">Kilometerstand</a></li>
            <li><a href="#box_4" className="linksToGridBox">Kfz von vorne</a></li>
            <li><a href="#box_5" className="linksToGridBox">Kfz von der rechten Seite</a></li>
            <li><a href="#box_6" className="linksToGridBox">Kfz von hinten</a></li>
            <li><a href="#box_7" className="linksToGridBox">Kfz von der linken Seite</a></li>
            <li><a href="#box_9" className="linksToGridBox">Detail-Foto 1 vom Schaden</a></li>
            <li><a href="#box_10" className="linksToGridBox">Detail-Foto 2 vom Schaden</a></li>
        </ul>
    );

    let infoText = (
        <p className="headerNormalText">
            Wenn Sie das Video und die Fotos nach Anleitung erstellt haben,
            drücken Sie bitte auf senden.<br />
            <span className="boldFont">Übertragungen aus Ihrem WLan vereinfachen und beschleunigen Ihren Datenversand!</span>
        </p>
    );

    let formatTexts = (
        <div>
            <p className="headerNormalText">
                Folgende Dateiformate mit einer
                max. Größe von {guiData.fileMaxSizeMultiPhoto} MB sind erlaubt:
                .jpg, .jpeg, .png, .gif
            </p>
            <p className="headerNormalText">
                Videoformate max. {guiData.fileMaxSizeMultiVideo} MB:
                .mov, .mp4, .m4v
            </p>
        </div>
    );
    
    let hasVideo = false;
    let hasPhotos = false;
    if (claimData.standardPhotosTexts !== null) {        
        let boxId = 0;
        photoTexts = (
            <ul>
            {claimData.claim.standardPhotosTexts.map((text, index) => {
                //todo: !!! Text for Schaden Übersicht button is missing here !!!
                if (text.includes("Video")) {                                                
                    hasVideo = true;
                    boxId = boxId + 1;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox"><span className="boldFont">Video rund um das Fahrzeug (max. 1min)</span></a></li>);
                } else if (text.includes("Fahrzeugschein")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Fahrzeugschein von vorne</a></li>);
                } else if (text.includes("Kilometerstand")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Kilometerstand</a></li>);
                } else if (text.includes("Fahrzeug von vorne")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Kfz von vorne</a></li>);
                } else if (text.includes("Fahrzeug von rechts")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Kfz von der rechten Seite</a></li>);
                } else if (text.includes("Fahrzeug von hinten")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Kfz von hinten</a></li>);
                } else if (text.includes("Fahrzeug von links")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Kfz von der linken Seite</a></li>);
                } else if (text.includes("Schaden Detail 1")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Detail-Foto 1 vom Schaden</a></li>);
                } else if (text.includes("Schaden Detail 2")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Detail-Foto 2 vom Schaden</a></li>);
                } else if (text.includes("Dokument 1") ||text.includes("Dokument 2")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Sonstige Dokumente z.B.: Kostenvoranschlag</a></li>);
                } else if (text.includes("Wildunfallbescheinigung")) {
                    boxId = boxId + 1;
                    hasPhotos = true;
                    return (<li key={boxId}><a href={"#box_"+boxId} className="linksToGridBox">Wildunfallbescheinigung</a></li>);
                }
                return null;
            })}
            </ul>
        )
        if (!hasVideo && hasPhotos) {
            infoText = (
                <p className="headerNormalText">
                    Wenn Sie die Fotos nach Anleitung erstellt haben,
                    drücken Sie bitte auf Übertragung starten.<br />
                    <span className="boldFont">Übertragungen aus Ihrem WLan vereinfachen und beschleunigen Ihren Datenversand!</span>
                </p>
            );
            formatTexts = (
                <p className="headerNormalText">
                    Folgende Dateiformate mit einer
                    max. Größe von {guiData.fileMaxSizeMultiPhoto} MB sind erlaubt:
                    .jpg, .jpeg, .png, .gif
                </p>              
            );
        } else if (hasVideo && !hasPhotos) {
            infoText = (
                <p className="headerNormalText">
                    Wenn Sie das Video nach Anleitung erstellt haben,
                    drücken Sie bitte auf senden.<br />
                    <span className="boldFont">Übertragungen aus Ihrem WLan vereinfachen und beschleunigen Ihren Datenversand!</span>
                </p>
            );
            formatTexts = (              
                <p className="headerNormalText">
                    Videoformate max. {guiData.fileMaxSizeMultiVideo} MB:
                    .mov, .mp4, .m4v
                </p>                
            );
        }
    }          

    return (
        <div>
        
            <div className="_container CarpicHeader">            
                <div className="card">
                    <div className="card-header headerCardHeader">
    Ihre Schadenmeldung vom {assignementDate} <span className="badge badge-primary badgeRight">Ihre Vorgangsnummer {claimData.claim.svp}</span>
                    </div>
                    <div className="card-body headerCardBody">                                            
                        <p className="headerHeadlineText">{anrede}</p>
                        <p className="headerNormalText">Ihre Schadennummer lautet: <strong>{claimData.claim.contractData.vu_schadennummer}</strong></p>
                        <div className="headerNormalText">
                            Um Ihren Schadenfall schnellstmöglich bearbeiten zu
                            können, fotografieren Sie Ihr Fahrzeug wie folgt:
                            {photoTexts}
                                
                            
                        </div>
                       {infoText}
                        <p className="headerNormalText">
                            Wir werden uns umgehend um Ihren
                            Fall kümmern und mit Ihnen in Kontakt treten.
                        </p>
                        <p className="headerNormalText">
                            Vielen Dank für Ihre Hilfe.
                        </p>
                        <div className="formatText">
                            {formatTexts}                            
                        </div>
                    </div>
                    <div className="card-footer headerCardFooter">&nbsp;</div>
                </div>
            </div>
        </div>
    );
}

export default Header;