import React, { useEffect}  from "react";

import * as rdd from "react-device-detect";

//Fiber
// import { useFrame } from '@react-three/fiber';


import logo from './logo.svg';
import './App.css';
import FastClaim from './components/fastclaim';
import MessageHeader from './layout/header/messageheader';
import { Provider } from "react-redux";
import store from "./store/storage";

function App() {

  // function Scene() {
  //   const ref = useRef();
  //   useFrame(() => {
  //       ref.current.rotation.x += 0.005;
  //       ref.current.rotation.y += 0.01;
  //   });

  //   return (
  //       <mesh ref={ref}>
  //           <boxGeometry args={[4, 4, 4]} />
  //           <meshStandardMaterial color="orange" />
  //       </mesh>
  //   );
  // }

  let view = (
    <div className="row">            
    <Provider store={store}>
        <FastClaim />
    </Provider>
    </div>
  );

  console.log("You are using "+rdd.browserName+" V "+rdd.browserVersion);
    let browserVersion = parseInt(rdd.browserVersion);
    if (rdd.isIOS && browserVersion <= 9) {
        let message = `You are Using ${rdd.browserName} Version ${rdd.browserVersion}. This Version from 2016 is because of Security Issues not supported by our Software. Please try to update your IPhone`;
        view  = (
            <MessageHeader 
                message={message}
                messageClass="alert-warning iosMessage"
            />             
        );
    }

    return (
        view
    );
}

export default App;
