import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import { appGuiActions } from "../store/app-gui-slice";
import { dataActions } from "../store/data-slice";

import Axios from "axios";
import Spinner from "react-bootstrap/Spinner"
import FileUploader from "./fileUpload/fileUploader";

import Layout from "../layout/layout";
import PhotoPreview from "./photo/photoPreview";

import MessageHeader from "../layout/header/messageheader";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import { getDefaultMiddleware } from "@reduxjs/toolkit";
// import { upload } from "@testing-library/user-event/dist/upload";

const damageIcon =  "/images/icons/detail2.png";
const documentIcon = "/images/icons/dokumente.png";

const BuildPhotoList = (dispatch, keyList, key) => {
    
    // const dispatch = useDispatch();    
    const neededPhotos =([]);
    let id=1;
    
    let damagePhotos = 0;
    let docPhotos = 0;
    let chargeType = "-1";
    
    let picturesComplete = true;    
    let standardPhotosTexts = [];
        
    const newKeylist = [...keyList];
    if (!keyList.includes("Bankkarte Vorderseite")) {
        newKeylist.push("Bankkarte Vorderseite");
    }    
    if (!keyList.includes("Bankkarte Rückseite")) {
        newKeylist.push("Bankkarte Rückseite");
    }    
    if (!keyList.includes("Vorschaden 1")) {
        newKeylist.push("Vorschaden 1");
    }    
    if (!keyList.includes("Vorschaden 2")) {
        newKeylist.push("Vorschaden 2");
    }    
    dispatch(dataActions.setAnsichten(newKeylist));
    
    const photoRequired = true;
    
    const photoList = ([
        {key: "Video",                  id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "video/webm", required: false,  fileName: "video", title: "Video rund um das Fahrzeug, max. 1 Minute", image: null, icon: "/images/icons/aufnahme.png", description: "Video rechts um das Fahrzeug, in gleichbleibendem Abstand", info: "", "skipbtn": false},            
        {key: "Fahrzeugschein",         id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "fhzgschein_image", title: "Fahrzeugschein", image: null, icon: "/images/icons/Zulassung.png", description: "Fahrzeugschein", info: "wie fotografiere ich den schein", "skipbtn": false},
        {key: "Kilometerstand",         id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "tacho_image", title: "Kilometerstand - bei laufendem Motor", image: null, icon: "/images/icons/tacho.png", description: "Tacho mit gut lesbarem Kilometer Stand", info: "darauf achten dass der blitz aus ist", "skipbtn": false},
        {key: "Fahrzeug von vorne",     id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "front_image", title: "Fahrzeug von vorne - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_front.png", description: "Fahrzeug Front", info: "", "skipbtn": false},
        {key: "Fahrzeug von rechts",    id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "rechts_image", title: "Fahrzeug von rechts - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_rechteSeite.png", description: "rechte Fahrzeug Seite", info: "", "skipbtn": false},
        {key: "Fahrzeug von hinten",    id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "heck_image", title: "Fahrzeug von hinten - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_back.png", description: "Fahrzeug Heck", info: "", "skipbtn": false},
        {key: "Fahrzeug von links",     id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "links_image", title: "Fahrzeug von links - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_linkeSeite.png", description: "linke Fahrzeug Seite", info: "", "skipbtn": false},
        {key: "Fahrzeug Reifen",        id: 0, type: 0, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "fahrzeugreifen_image", title: "Fahrzeug Reifen - Abstand ca. 1,5 - 2m", image: null, icon: "/images/icons/reifen.png", description: "Fahrzeug Reifen", info: "", "skipbtn": false},
        {key: "Dokument 1",             id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: documentIcon, description: "", info: "", "skipbtn": true},
        {key: "Dokument 2",             id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: documentIcon, description: "", info: "", "skipbtn": false},
        {key: "Schaden Übersicht",      id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "gesamtschaden_image", title: "Schaden Übersicht", image: null, icon: "/images/icons/detail1.png", description: "", info: "", "skipbtn": false},
        {key: "Schaden Detail 1",       id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "detail1_image", title: "Schaden Foto - im Detail 1 Abstand 1 - 2m", image: null, icon: damageIcon, description: "", info: "", "skipbtn": false},
        {key: "Schaden Detail 2",       id: 0, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired,  fileName: "detail2_image", title: "Schaden Foto - im Detail 2 Abstand 0,5 - 1m", image: null, icon: damageIcon, description: "", info: "", "skipbtn": false},
        {key: "Vorschaden 1",           id: 0, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "vorschaden1_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: documentIcon, description: "Hatten Sie an Ihrem Fahrzeug schon einen Vorschaden?", info: "", "skipbtn": false},                            
        {key: "Vorschaden 2",           id: 0, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "vorschaden2_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: documentIcon, description: "", info: "", "skipbtn": false},                            
        {key: "Wildunfallbescheinigung",id: 0, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",       required: true, fileName: "wildunfallbescheinigung_image", title: "Foto der Wildunfallbescheinigung", image: null, icon: "/images/icons/wildunfallbescheinigung.png", description: "Wildunfallbescheinigung", info: "", "skipbtn": false},                            
        {key: "Bankkarte Vorderseite",  id: 0, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardfront_image", title: "Bank Karte Vorder-Seite", image: null, icon: "/images/icons/credit_card_front.png", description: "", info: "", "skipbtn": false},
        {key: "Bankkarte Rückseite",    id: 0, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardback_image", title: "Bank Karte Rück-Seite", image: null, icon: "/images/icons/credit_card_back.png", description: "", info: "", "skipbtn": false},                            
    ]);
    let isUploading = [];
console.log("buidl photolist");
    photoList.map((photo, index) => {        
        
        photo.id = id;        
        if (newKeylist.includes(photo.key)) {
            photo.required = photoRequired;
            id = id + 1;

            standardPhotosTexts.push(photo.key);
            //check if we already upoaded the photo
            let uploadedString = localStorage.getItem(key+"_up_"+photo.id);
            let uploaded = true;
            if (photo.key === "Bankkarte Vorderseite" || photo.key === "Bankkarte Rückseite") {
                chargeType = "-2";
                photo.required = false;
            }
            
            
            if (photo.key === "Vorschaden 1" || photo.key === "Vorschaden 2") {                    
                photo.required = false;
            }
            if (uploadedString === null || uploadedString === "undefined") {
                uploaded = false;
                if (photo.required) {
                    console.log(photo.key+" is required");
                    picturesComplete = false;
                } else {
                    console.log(photo.key+" is NOT required");
                }
            }
            const metaJson = localStorage.getItem(key+"_meta_"+photo.id);
            let metadata = null;       
            if (metaJson !== null && typeof metaJson === "string") {
                try {
                    metadata = JSON.parse(metaJson);       
                } catch(e) {
                    console.log("onBuildPhotoList(): the string is not a json string");
                    metadata = null;
                }
            }
            
            photo.uploaded = uploaded;
            photo.metadata = metadata;            
            
            neededPhotos.push(photo);  
            isUploading[photo.id] = false;              
            if (photo.type === 2) {
                docPhotos = docPhotos + 1; 
                dispatch(dataActions.setHasDocuments(true));
            } else if (photo.type === 1) {
                damagePhotos = damagePhotos + 1; 
                dispatch(dataActions.setHasDamagePhotos(true));
            }            
        }        
        return true;
    });        
    
    dispatch(appGuiActions.setUploadFinished(picturesComplete));
    dispatch(appGuiActions.setModalVisible(picturesComplete));
    
    dispatch(dataActions.setStandardPhotosText(standardPhotosTexts));
    dispatch(dataActions.setKey(key));
    dispatch(dataActions.setPhotoList(neededPhotos));
    dispatch(dataActions.setMaxPhotoId(id-1));
    dispatch(appGuiActions.setDamageDetailPhotos(damagePhotos));
    dispatch(appGuiActions.setDocuments(docPhotos));
    dispatch(dataActions.setChargeType(chargeType));    
    dispatch(appGuiActions.setMoreButtons(false));    
    
    dispatch(appGuiActions.setIsUploading(isUploading));
}

const FastClaim = (props) => {

    const dispatch = useDispatch();

    const claimData = useSelector((state) => state.data);    
    const guiData = useSelector((state) => state.appgui);
    

    let queryString = new URLSearchParams(window.location.search);
    const key= queryString.get("key");
    
    const systemID = guiData.systemData.systemID;
    const storePhotos = [...claimData.claim.standardPhotos];
    const callUrl = guiData.systemData.dataUrl+key;
    
    useEffect(() => {                
        console.log("FastClaim started");

        const getInitialData = async() => {

            console.log("init data");           
            
            //try to get previously taken photos from local storage
            const standardPhotos = [...storePhotos];
            let newStandardPhotos = [];   
                
            const formData = new FormData();
            formData.append("key", key);
            formData.append("systemID", systemID);
            
            try {            
                const res = await Axios.get(callUrl, formData, null);                
               
                const contractData = res.data.data;
                if (!contractData || contractData === null) {                    
                    dispatch(dataActions.resetContractData()); 
                    dispatch(appGuiActions.setErrorMessage(res.data.message)); 
                    dispatch(appGuiActions.setDataLoaded(true)); 
                    console.log("No Data received: ");                    
                } else if (contractData === "Invalid key." || contractData === null) {
                    console.log("Error: "+contractData);                    
                } else {                    
                    console.log("Response data from: "+contractData.v_schadennr);
                    console.log("response OK, data received");
                    const maxPhotoSize = res.data.config.max_photo_size;
                    const maxVideoSize = res.data.config.max_video_size;
                        
                    let numberPlate = localStorage.getItem(key+"_fzg_kennzeichen");            
                    if (numberPlate && contractData.fzg_kennzeichen.length === 0) {
                        contractData.fzg_kennzeichen = numberPlate;
                    }
                    let internalNumber = localStorage.getItem(key+"_v_schadennr");            
                    if (internalNumber && contractData.v_schadennr.length === 0) {
                        contractData.v_schadennr = internalNumber;
                    }
                    
                    contractData.gutachten_nr_formatiert = contractData.svpro_nr;
                    
                    console.log("max: "+guiData.fileMaxSizeMultiPhoto);

                    dispatch(appGuiActions.setFileMaxSizeMultiPhoto(maxPhotoSize));
                    dispatch(appGuiActions.setFileMaxSizeMultiVideo(maxVideoSize));
                    dispatch(dataActions.setContractData(contractData));
                    dispatch(appGuiActions.setDataLoaded(true));
                    dispatch(appGuiActions.setAppInitialized(true));

                    if (contractData.ansichten !== "*" && contractData.ansichten[0] !== "*") {
                        console.log("Startbuilding Photolist");
                        BuildPhotoList(dispatch, contractData.ansichten, key);                            
                    } else {
                        let picturesComplete = true;       
                        standardPhotos.forEach(photo => {            
                            let uploadedString = localStorage.getItem(key+"_up_"+photo.id);       
                            let uploaded = true;
                            if (uploadedString === null || uploadedString === "undefined") {
                                uploaded = false;
                                if (photo.required) {
                                    picturesComplete = false;
                                }
                            }
                            
                            const metaJson = localStorage.getItem(key+"_meta_"+photo.id);
                            let metadata = null;       
                            if (metaJson !== null && typeof metaJson === "string") {
                                try {
                                    metadata = JSON.parse(metaJson);       
                                } catch(e) {
                                    console.log("ComponentDidMount(): the string is not a json string");
                                    metadata = null;
                                }
                            }
            
                            photo.uploaded = uploaded;
                            photo.metadata = metadata;                       
                            newStandardPhotos.push(photo); 
                        });  
                     
                    dispatch(dataActions.setPhotoList(newStandardPhotos))
                    dispatch(appGuiActions.setUploadFinished(picturesComplete));
                    dispatch(appGuiActions.setModalVisible(picturesComplete));                       
                    dispatch(dataActions.setKey(key));
                    }                        
                    }
                    // dispatch(dataActions.dataReceived(true));                
            } catch (error) {                          
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.header);
                    if (error.response.status === 400) {
                        error.message = error.response.data.message;
                        dispatch(dataActions.resetContractData()); 
                        dispatch(appGuiActions.setErrorMessage(error.response.data.message)); 
                        dispatch(appGuiActions.setDataLoaded(true));                     
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log("Error", error.message);
                }      




                dispatch(appGuiActions.setNetworkError({status: true, message: error.message}));                              
            }
        };
        if (/*key && key.length > 0 && */!claimData.dataReceived) {
            getInitialData().catch(error => {            
                dispatch(appGuiActions.setNetworkError({status: true, message: "Error while fetching network Data"}));            
            });
        }

    }, [key,dispatch]);

    const changeView = (data) => {
        dispatch(appGuiActions.setView(data.view));
        dispatch(dataActions.setCurrentPhoto(data));        
    }
   
    const openPhoto = (photoData) => {
        console.log("Carpic OpenPhoto:",photoData);
    }
    
    
    const showPreview = (photoData) => {
        dispatch(appGuiActions.setView("preview"));
        dispatch(appGuiActions.setCurrentPhoto(photoData));        
    }

    let uploadUrl = "";

    // const setModalVisible = () => {
    //     console.log("Fastclaim.js::setModalVisible");
    //     dispatch(appGuiActions.setModalVisible(false));
    // }

    let view = (
        <div>
            <Layout 
                changeView={changeView}
                openPhoto={openPhoto}                                
                showPreview={showPreview}                
                />                   
        </div>
    );
    if (guiData.view === "cam") {

    } else if (guiData.view === "preview") {
        view = <PhotoPreview 
                    photoData={claimData.claim.currentPhoto}                
                    changeView={changeView}
                />
    }  else if (guiData.view === "upload") {
        view = <FileUploader 
                    changeView={changeView}                    
                />
    }

    if (guiData.dataLoaded === false) {
        view = (
            <div className="container">                
                <div className="row">
                    <div className="col-md-5"></div> 
                    <div className="col-md-2">
                        <Spinner animation="border" variant="warning" />
                    </div> 
                    <div className="col-md-5"></div> 
                </div>        
            </div>
        )
    }

    let message = "Konnte die benötigten Daten nicht vom Server laden";
    if (!key || key.length === 0) {
        message = <div>kein key</div>
    }

    if (guiData.dataReceived === false || claimData.claim.contractData === null) {
        if (claimData.claim.contractData === null) {                
            message = "Es konnten keine Daten für den benutzten Key gefunden werden. Es ist möglich, dass der Key bereits abgelaufen ist."
            if (guiData.errorMessage.length > 0) {
                message = guiData.errorMessage;
            }
        }
        view = (
            <MessageHeader 
                message={message}
                messageClass="alert-warning"
            />                    
        )
    } 
    return view;
}

export default FastClaim;