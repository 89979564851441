import { createSlice } from "@reduxjs/toolkit";

const imageDefaultPath = process.env.PUBLIC_URL+"/images/";

const initialState = {
    // partList: partList,
    fileMaxSizeMultiPhoto: 2,
    fileMaxSizeMultiVideo: 15,
    systemData : {
        systemID: "dev",
        // dataUrl: "https://carpic-dev.svpro.de/backend/getdata.php?key=",//DEV            
        dataUrl: "https://api-dev.svpro.de/api/v1/carpic/",
        // uploadTarget: "https://carpic-dev.svpro.de/backend/",//DEV
        uploadTarget: "https://api-dev.svpro.de/api/v1/carpic",//DEV

        // systemID: "test",
        // dataUrl: "https://carpic-test.svpro.de/backend/getdata.php?key=",
        // uploadTarget: "https://carpic-test.svpro.de/backend/",
        
        // systemID: "devgs",
        // dataUrl: "https://carpic-test.svpro.de/backend/getdata.php?key=",//DEV            
        // uploadTarget: "https://carpic-test.svpro.de/backend/",//DEV
    },
    view: "main",
    jitsiStarted: false,
    isLoading: false,
    appInitialized: false,
    transferFinished: false,   
    dataComplete: false,
    uploading: false,     
    uploadFinished: true, 
    // isUploading: [false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false, false, false],            
    jobCanceled: false,
    dataLoaded: false,   
    resultCode: null,
    networkError: false,
    dataErrors: 0,
    generalError: 0,
    errorMessage: "",
    message: {className: "", message: ""},
    dsgvoAccepted: false,
    modalVisible: false,
    imageDefaultPath: imageDefaultPath,  
    
    photoSize: {
        width: 480,
        height: 360
    },
    moreButtons: true,   
    damageDetailPhotos: 2,
    damageDetailPhotoMax: 5,
    damageDetailAddButton: true,
    documents: 1,
    documentMax: 5,
    documentAddButton: true,
    currentPhoto: null,
    hasDocuments: true,
};

const appGuiSlice = createSlice({
    name: "appgui",
    initialState,
    reducers : {
        changeView(state, action) {
            state.view = action.payload;
        },
        setUploadFinished(state, action) {
            state.uploadFinished = action.payload;
        },
        setJobCanceled(state, action) {
            state.jobCanceled = action.payload;
        },
        setDataLoaded(state, action) {
            state.dataLoaded = action.payload;
        },
        setResultCode(state, action) {
            state.resultCode = action.payload;
        },
        setDsgvoAccepted(state, action) {
            state.dsgvoAccepted = action.payload;
        },
        setAppInitialized(state, action) {
            state.appInitialized = action.payload;
        },
        setTransferFinished(state, action) {
            state.transferFinished = action.payload;
        },
        setDataComplete(state, action) {
            state.dataComplete = action.payload;
        },
        setModalVisible(state, action) {
            state.modalVisible = action.payload;
        },
        setMoreButtons(state, action){
            state.moreButtons = action.payload;
        }, 
        setIsUploading(state, action) {
            state.isUploading = action.payload;
        },
        setUploading(state, action) {
            state.uploading = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setDamageDetailPhotos(state, action) {
            state.damagePhotos = action.payload;
        },
        setDamageDetailAddButton(state, action) {
            state.damageDetailAddButton = action.payload;
        },
        setDocuments(state, action) {
            state.docPhotos = action.payload;
        },
        setFileMaxSizeMultiPhoto(state, action) {
            state.fileMaxSizeMultiPhoto = action.payload;
        },
        setFileMaxSizeMultiVideo(state, action) {
            state.fileMaxSizeMultiVideo = action.payload;
        },
        setView(state, action) {
            state.view = action.payload;
        },
        setCurrentPhoto(state, action) {
            state.currentPhoto = action.payload;
        },
        setMessage(state, action) {
            state.message = action.payload;
        },
        setErrorMessage(state, action) {
            state.errorMessage = action.payload;
        },
        setGeneralError(state, action) {
            state.generalError = action.payload;            
        },
        setDataErrors(state, action) {
            state.dataErrors = action.payload;            
        },
        setNetworkError(state, action) {
            state.networkError = action.payload.status;
            state.errorMessage = action.payload.message;
        }
    } 
});
export const appGuiActions = appGuiSlice.actions;
export default appGuiSlice.reducer;