import React from "react";
import {useSelector, useDispatch} from "react-redux";
import { appGuiActions } from "../../store/app-gui-slice";
import { dataActions } from "../../store/data-slice";


import PhotoBox from "./photoBox";
import "./photoList.css";
// import { getDefaultMiddleware } from "@reduxjs/toolkit";

const PhotoList = (props) => {

    

    const claimData = useSelector((state) => state.data);     
    const guiData = useSelector((state) => state.appgui);
    const dispatch = useDispatch();    
    const addDamageDetail = () => {
        if (guiData.damageDetailPhotos < guiData.damageDetailPhotoMax) {
            const standardPhotos = [...claimData.standardPhotos];
            let newId = claimData.maxPhotoId +1;
            let newDamagePhotos = claimData.damageDetailPhotos + 1;
            let isUploading = guiData.isUploading;
            const newDamagePhoto = {
                id: newId, 
                type: 1, 
                fileData: null, 
                format: "img/jpeg", 
                required: false, 
                title: `Detail ${newDamagePhotos}`, 
                fileName: `detail${newDamagePhotos}_image`,
                image: null, 
                url: null, 
                metadata: null,
                icon: claimData.damageIcon, 
                description: "", 
                info: ""        
            };

            let newStandardPhotos = [];
            
            standardPhotos.forEach(photo => {            
                newStandardPhotos.push(photo); 
            });
            newStandardPhotos.push(newDamagePhoto);
            dispatch(dataActions.addPhoto(newDamagePhoto));                   
            isUploading[newId] = false;


            dispatch(dataActions.setMaxPhotoId(newId));
            dispatch(appGuiActions.setDamageDetailPhotos(newDamagePhotos));
            // dispatch(dataActions.updatePhotoList(newStandardPhotos));                   
            dispatch(appGuiActions.setIsUploading(isUploading));
            
            if(newDamagePhotos >= this.state.damageDetailPhotoMax) {
                dispatch(appGuiActions.setDamageDetailAddButton(false));                
            }            
        }
    }

    const addDocuments = () => {
        if (this.state.documents < this.state.documentMax) {
            const standardPhotos = [...this.state.standardPhotos];
            let newId = this.state.maxPhotoId +1;
            let curDocId = this.state.documents;
            let isUploading = this.state.isUploading;
            const newDocumentPhoto = {
                id: newId, 
                type: 2, 
                fileData: null, 
                format: "doc", 
                required: false, 
                title: `Dokument ${this.state.documents}`, 
                fileName: `document${curDocId+1}_image`,
                image: null, 
                url: null, 
                icon: this.documentIcon, 
                metadata: null,
                description: "", 
                info: ""        
            };
            let newDocuments = curDocId + 1;

            let newStandardPhotos = [];
            isUploading[newId] = false;
            
            standardPhotos.forEach(photo => {            
                newStandardPhotos.push(photo); 
            });
            newStandardPhotos.push(newDocumentPhoto);

            this.setState ({
                maxPhotoId: newId,
                documents: newDocuments,
                standardPhotos: newStandardPhotos,
                isUploading: isUploading
            });
            if(newDocuments >= this.state.documentMax) {
                this.setState({
                    documentAddButton: false
                })
            }
        }        
    }    

    let photoListTitle = null;
    if (props.type === "dam") {
        photoListTitle = "Schaden Fotos";
    } else if (props.type === "doc") {
        photoListTitle = "Sonstige Dokumente";        
    } else if (props.type === "vorschaden") {
        photoListTitle = "";
    }    

    let key = 1000;

    let addButton =  "";
    //if ( || props.type === "doc") {
    if (props.type === "dam" && guiData.damageDetailAddButton === true) {
        addButton = (            
        <div>
            <button key={key++} type="button" className="btn btn-warning bt-block" onClick={addDamageDetail}>Schaden Foto hinzufügen</button>
        </div>
        );
    }

    if (props.type === "doc" && guiData.documentAddButton === true) {
        addButton = (            
        <div>
            <button key={key++} type="button" className="btn btn-warning bt-block" onClick={addDocuments}>Dokument hinzufügen</button>
        </div>
        );
    }

    if (!guiData.moreButtons) {
        addButton = null;
    }

    let gridClassName = "";
    if (props.className) {
        gridClassName = props.className+"Grid";
    }


    let photoListBox = (                
        <div>
            <div className="photoListBoxHeader">&nbsp;</div>
            {photoListTitle? <h3 className="photoListHeaderTitle">{photoListTitle}</h3> : ""}          
            <div className={"photoGrid "+gridClassName}>    
            {
                claimData.claim.standardPhotos.map((photo, index) => {   
                    let photoType = photo.type;
                    if (props.type === "dam") {
                        console.log("dam fotos - "+photo.key);
                    }                                                    
                    if ((props.type === "std" && photo.type === 0) 
                    || (props.type === "dam" && photo.type === 1 && guiData.damageDetailPhotos > 0) 
                    || (props.type === "bank" && photo.type === 3) 
                    || (props.type === "vorschaden" && photo.type === 4)
                    || (props.type === "doc" && photo.type === 2 && guiData.documents > 0)) { 
                        return (
                            <PhotoBox 
                                assKey={claimData.key}
                                uploadTarget={guiData.systemData.uploadTarget}        
                                contractData={claimData.claim.contractData}                
                                type={props.type} 
                                phototype={photo.type} 
                                key={photo.id}
                                id={photo.id}
                                format={photo.format}
                                svp={claimData.claim.contractData.svp}
                                title={photo.title}
                                fileName={photo.fileName} 
                                fileMaxSizeMultiPhoto={guiData.fileMaxSizeMultiPhoto}
                                fileMaxSizeMultiVideo={guiData.fileMaxSizeMultiVideo}
                                image={photo.image} 
                                fileData={photo.fileData}
                                required={photo.required}
                                uploaded={photo.uploaded}
                                metadata={photo.metadata}
                                icon={photo.icon} 
                                errorMessage={guiData.errorMessage}
                                generalError={guiData.generalError}  
                                uploadError={guiData.uploadError}                                
                                description={photo.description} 
                                skipbtn={photo.skipbtn}
                                openPhoto={props.openPhoto}
                                changeView={props.changeView}
                                showPreview={props.showPreview}
                                className={props.className}                                  
                                isUploading={guiData.isUploading}                              
                            />
                            );
                        } else {            
                            console.log("ClaimPhotos: "+claimData.claim.standardPhotos.length);
                            console.log("Type: "+props.type+" FotoType: "+photo.type);
                            return null;
                        }
                    })                
                }
                {addButton}
            </div>
        </div>
    );
    
    
    if ((props.type === "dam" && guiData.damageDetailPhotos === 0) 
        || (props.type === "doc" && guiData.documents === 0)) {        
            photoListBox = null;
    }
    if (props.type === "doc" && !claimData.claim.hasDocuments) {
        photoListBox = null;
    }
    if (props.type === "dam" && !claimData.claim.hasDamagePhotos) {
        photoListBox = null;
    }

    return (   
        photoListBox
    );
}

export default PhotoList;