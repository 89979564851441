import React from "react";
import "./messageModal.css";

const MessageModal = (props) => {

    return (
        <div>
            <div className="modalBackdrop" onClick={props.onConfirm} />
            <div className="modalMessage modalCard">
                <header className="modalHeader">
                    <h2>{props.title}</h2>
                </header>    
                <div className="modalContent">
                    <p>{props.message}</p>
                </div>
                <footer className="modalActions">
                    <button className="btn btn-primary" onClick={props.onConfirm}>{props.buttonLabel}</button>
                </footer>
            </div>
        </div>
    );
}

export default MessageModal;