import React, {useEffect} from "react";

import {useSelector, useDispatch} from "react-redux";
import { appGuiActions } from "../../store/app-gui-slice";
import { dataActions } from "../../store/data-slice";


import Axios from "axios";
import "./fileUploader.css";
import Spinner from "react-bootstrap/esm/Spinner";

const FileUploader = (props) => {

    const dispatch = useDispatch();

    const claimData = useSelector((state) => state.data);    
    const guiData = useSelector((state) => state.appgui);    
    /*
        put all other data the user inouts together into the contract we received from the server (update fields) and send this to the server as well - SOAP
    
    */ 

    const getChargeType = () => {
        //chargetype mapper
        let chargeType = -1;
        if (claimData.claim.chargeType === 0 || claimData.claim.chargeType === "0") {
            chargeType = 4;
        } else if (claimData.claim.chargeType === 1 || claimData.claim.chargeType === "1") {
            chargeType = 3;
        }
        return chargeType;
    }

    const getChargeTypeString = () => {
        //chargetype mapper
        let chargeType = (            
            <div className="alert alert-warning">Bitte wählen Sie eine Abrechnungsart aus, wenn Sie noch unentschlossen sind, dann können Sie sich auch später entscheiden</div>
        );
        if (claimData.claim.chargeType === 0 || claimData.claim.chargeType === "0") {
            chargeType = "Reparatur";
        } else if (claimData.claim.chargeType === 1 || claimData.claim.chargeType === "1") {
            chargeType = "fiktive Abrechnung, Auszahlung";
        }

        return chargeType;
    }

    const clearAssignementData = () => {
        //1. check if the user really wants to do that
        //2. remove photos from server
        //3. remove everything for the given KEY from the local storage        
        const standardPhotos = [...claimData.claim.standardPhotos];
        let newStandardPhotos = [];

        let removeOK = true;

        const svpNumber = claimData.claim.svp;
        const key = claimData.claim.key;
        if (removeOK) {

            const body = new FormData()
            body.append("action", "cancel");            
            body.append("key", key);
            body.append("svp", svpNumber);

            const callUrl = guiData.systemData.uploadTarget;

            Axios.post(callUrl+"cancel", body).then(res => {
                const data = res.data;
                if (data.code === 200) {                    
                    standardPhotos.forEach(photo => {  
                        let currentPhoto = {...photo};
                        if (currentPhoto.uploaded) {               
                            localStorage.removeItem(key+"_up_"+currentPhoto.id);                   
                            localStorage.removeItem(key+"_meta_"+currentPhoto.id);                                        
                            console.log("REMOVE localStorage entry for ", key, currentPhoto.id, svpNumber);                                                                         
                            currentPhoto.uploaded = false;
                            currentPhoto.metadata = null;
                            currentPhoto.fileData = null;
                            currentPhoto.image = null;
                            newStandardPhotos.push(currentPhoto); 
                            const payload = {
                                id: currentPhoto.id,
                                uploaded: false,
                                metadata: null,
                                fileData: null,
                                image: null
                            };
                            dispatch(dataActions.updatePhotoStatus(payload));         
                        }
                    });

                    // dispatch(dataActions.updatePhotoList(newStandardPhotos));     
                    dispatch(appGuiActions.setUploadFinished(false));     
                    dispatch(appGuiActions.setModalVisible(false));                         
                    dispatch(appGuiActions.setJobCanceled(true));                        
                } else if (data.code === 40006) {
                    //nothing to delet on server
                    dispatch(appGuiActions.setUploadFinished(false));     
                    dispatch(appGuiActions.setModalVisible(false));                         
                    dispatch(appGuiActions.setJobCanceled(true));                        
                }
            }).catch(
                (error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.header);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }                           
                }
            );           
        }

    }

    const onStartTransfer = () => {        
        dispatch(appGuiActions.setIsLoading(true));
        
        
        let photoList = [];
        const standardPhotos = [...claimData.claim.standardPhotos];
        console.log("Start transfer: ",standardPhotos);        
        standardPhotos.forEach(photo => {            
            let format = photo.metadata !== null ? photo.metadata.type.split("/") : [];
            if (format.length === 2) {
                format = format[1];
            } else {
                format = "undefined";
            }
            let photoName = claimData.claim.svp+"."+photo.id+"."+format;
            photoList.push(photoName);
        });
            
        let chargeType = getChargeType();

        let firstName = "";
        let familyName = "";
        let iban = "";
        if (claimData.claim.customer.bankData !== null) {
            firstName = claimData.claim.customer.bankData.firstname;
            familyName = claimData.claim.customer.bankData.lastname;
            iban = claimData.claim.customer.bankData.iban;
        }        

        //0: kein Vorschaden
        //1: vorschaden
        //2: vorschaden nicht repariert  
        //3: vorschaden repariert  
        let vorschadenStatus = -1;
        if (claimData.claim.vorSchaden === 0 || claimData.claim.vorSchaden === "0") {
            vorschadenStatus = 0;
        } else if (claimData.claim.vorSchaden === 1 || claimData.claim.vorSchaden === "1") {
            vorschadenStatus = 1;
        }
        if (claimData.claim.vorschadenDetail === 0 || claimData.claim.vorschadenDetail === "0") {
            //Der Schaden wurde bereits Instandgesetzt - +Amount
            vorschadenStatus = 3;
        } else if (claimData.claim.vorschadenDetail === 1 || claimData.claim.vorschadenDetail === "1") {
            //Der Schaden wurde nicht repariert
            vorschadenStatus = 2;
        }

        console.log("VorschadenStatus: "+vorschadenStatus);
        console.log("vorschadenDetail: "+claimData.claim.vorschadenDetail);
        console.log("VorschadenAmount: "+claimData.claim.vorschadenAmount);

        //build piclist
        let picList = "";
        
        standardPhotos.map((photo, index) => {
            console.log("PicList: "+photo.fileName);
            if (photo.required) {
                if (picList !== "") {
                    picList += "|";
                }
                picList += ""+photo.fileName;
            }
        });

        const data = new FormData();            
        data.append("send", 1);
        data.append("systemID", guiData.systemData.systemID);
        data.append("svp", claimData.claim.svp);
        data.append("piclist", picList);
        data.append("key", claimData.claim.key);
        data.append("name", familyName);
        data.append("vorname", firstName);
        data.append("iban", iban);
        data.append("vorschadenstatus", vorschadenStatus);
        data.append("vorschadenamount", claimData.claim.vorschadenAmount);
        data.append("abrechnungswunschauswahl", chargeType);
        const copartFlag = claimData.claim.choose_copart
        data.append("choose_copart", copartFlag);
        data.append("customer_mail", claimData.claim.customer.mail);
        data.append("customer_phone", claimData.claim.customer.phone);
        
        let testUrl = guiData.systemData.uploadTarget+"send";

        console.log("Data: ",data);
        let response = Axios({
            method: "post",
            url: testUrl,                                
            data: data, 
            // 'Access-Control-Allow-Origin': '*',
            // 'Content-Type': 'application/json',
            headers: {
                "X-Requested-Width": "XMLHttpRequest",
                'Access-Control-Allow-Origin': '*',
                'Content-Type' : `multipart/form-data;`
            },               
        })
        .then(response => {             
            if (response.data.code !== null) {
                console.log("onStartTransfer(): code: ", response.data.code);
                console.log("onStartTransfer(): message: ", response.data.message);

                dispatch(appGuiActions.setResultCode(response.data.code));     
                dispatch(appGuiActions.setErrorMessage(response.data.message));     
                dispatch(appGuiActions.setIsLoading(false));     
                dispatch(appGuiActions.setTransferFinished(true));     
            } else {
                console.log("onStartTransfer(): Response: ", response.data);
                console.log("onStartTransfer(): code: ", response.data.code);
                console.log("onStartTransfer(): message: ", response.data.message);                
                
                dispatch(appGuiActions.setNetworkError({status: false, message: ""}));     
                dispatch(appGuiActions.setIsLoading(false));     
            }
            return response.data
        })
        .catch(response => {
            console.log("Server Exception: ",response);
            response = {
                success: 500,
                message: "Datei konnte nicht übertragen werden",
                data: ""
            }            
            dispatch(appGuiActions.setNetworkError({status: true, message: response.message}));     
            dispatch(appGuiActions.setIsLoading(false));     
        }
        );
        
        console.log("Transfer finished");

        //cleanup localStorage
    }

    useEffect(() => {
        let dataComplete = true;
        if (claimData.claim.standardPhotos.length > 0) {
            let standardPhotos = [];
            let curErrors = guiData.dataErrors;
            let missingImageList = claimData.claim.missingImageList;
            claimData.claim.standardPhotos.forEach(photo => {
                if (photo.image != null || photo.fileData != null || photo.uploaded === true) {
                    //&& photo.format !== "video/webm"
                    const fileItem = ({
                        token: claimData.claim.key,
                        svp: claimData.claim.svp,
                        id: photo.id, 
                        key: photo.key,
                        type: photo.type, 
                        format: photo.format,
                        imagedata: photo.image,
                        fileData: photo.fileData,
                        title: photo.title, 
                        uploaded: photo.uploaded,
                        metadata: photo.metadata,
                        required: photo.required,
                        fileName: photo.fileName,
                        image: photo.image,
                        icon: photo.icon,
                        info: photo.info,
                        description: photo.description,
                        status: 0, 
                        progress: 0
                    });                    
                    standardPhotos = [...standardPhotos, fileItem];
                } else {
                    if (photo.required && photo.image == null && photo.fileData === null && photo.uploaded === false) {
                        dataComplete = false;                            
                        missingImageList.push(photo.title);                                            
                        curErrors = curErrors+1;
                    }                    
                    standardPhotos = [...standardPhotos, photo];
                }
            });

            let message = {
                //className: "alert-warning",
                //message: `Es müssen noch ${missingImageList.length} Dokumente übertragen werden`
            };
            if (missingImageList.length === 0) {
                message = "";
            }            
            dispatch(dataActions.setPhotoList(standardPhotos));     
            console.log("fileUpload::useEffect foto count claim: "+claimData.claim.standardPhotos.length);
            console.log("fileUpload::useEffect foto count standardPhotos: "+standardPhotos.length);
            dispatch(appGuiActions.setDataErrors(curErrors));     
            dispatch(appGuiActions.setDataComplete(dataComplete));     
            dispatch(dataActions.setMissingImageList(missingImageList));     
            dispatch(appGuiActions.setMessage(message));                 
        } 
    }, []);
    

    // render() {   
        let buttonDisabled = false;
        if (/*getChargeType() === -1 ||  */!guiData.uploadFinished || !guiData.dsgvoAccepted) {
            buttonDisabled = true;
        }
        let transferButton = (
            <div>
                <button type="button" disabled={buttonDisabled}className="btn btn-warning my-btn-block krugButtonColor" onClick={() => onStartTransfer()}>
                    Übertragung starten
                </button>
                <div>Bitte warten Sie bis alle Daten vollständig übertragen sind!</div>
            </div>
        );


        let InfoBox = (
        <div className={"alert "+guiData.message.className}>{guiData.message.message}</div>
        );
        if (!guiData.message.message || guiData.message.message === "") {
            InfoBox = null;
        }
       
        let bankData = null;
        if (claimData.claim.customer.bankData !== null) {
            let accountHolder = "";
            if (claimData.claim.customer.bankData.firstname.length > 0) {
                accountHolder = claimData.claim.customer.bankData.firstname;
            }
            if (claimData.claim.customer.bankData.lastname.length > 0) {
                if (accountHolder.length > 0) {
                    accountHolder = accountHolder+" ";
                }
                accountHolder = accountHolder+claimData.claim.customer.bankData.lastname;
            }
            if (accountHolder.length > 0 || claimData.claim.customer.bankData.iban.length > 0) {
                bankData = (
                    <div>
                        <div>Kontoinhaber: {accountHolder}</div>
                        <div>IBAN: {claimData.claim.customer.bankData.iban}</div>
                    </div>
                )
            }
        }

        let transferErrorMessage = null;
        if (guiData.resultCode !== null && guiData.resultCode !== 1 && guiData.errorMessage) {
            transferErrorMessage = (
                <div className="row marginTop30">
                    <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {guiData.errorMessage}</div>
                </div>
            );
        }

        if (guiData.errorMessage !== null && guiData.errorMessage.length > 0) {
            transferErrorMessage = (
                <div className="row marginTop30">
                    <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {guiData.errorMessage}</div>
                </div>
            );
        }

        let transferBox = (
            <div className="row marginTop30">
                {transferErrorMessage}                
                 <div className="col-md-6 col-sm-12">
                        {transferButton}
                    </div>                    
                    <div className="col-md-6 col-sm-12">
                        <button type="button" className="btn btn-warning my-btn-block _buttonCancel _contolButtons" onClick={clearAssignementData}>Abbrechen</button>
                        <div>Durch das Betätigen des "Abbrechen" Buttons, werden alle von Ihnen bisher für diesen Vorgang erfassten Dokumente (auch Fotos und Videos) gelöscht</div>
                    </div>
            </div>
        );
        
        let abrechnungsLine = "";
        if (getChargeType() === 4 || getChargeType() === 3 || getChargeType() === -1 || getChargeType() === -2) {
            abrechnungsLine = (
                <div className="chargeType">Abrechnung: {getChargeTypeString()}</div>
            );
        }

        if (guiData.resultCode !== null) {
            if (guiData.resultCode === 1) {
                transferBox = (
                    <div className="row marginTop30">
                        <div className="alert alert-success col-sm-12">
                            <p>Vielen Dank für die Übertragung Ihrer Daten.<br></br>
                            Wir werden uns umgehend um Ihren Schadenfall kümmern und setzen uns zeitnah mit Ihnen in Verbindung.</p>
                        </div>
                    </div>
                );
                abrechnungsLine = null;
            } else {
               transferBox = (
                    <div className="row marginTop30">
                        <div className="alert alert-danger col-sm-12">Fehler bei der Übertragung: {guiData.errorMessage}</div>
                    </div>
                )
            }
        }

        if (guiData.isLoading === true) {
            transferBox = (            
                <div className="row alert alert-primary">
                    <div className="row col-md-12 marginBottom15 centerBox">Daten werden übertragen, Bitte warten Sie bis der Vorgang abgeschlossen ist</div>
                    <div className="row col-md-12 centerBox">
                        <Spinner  animation="border" role="status">
                            <span className="sr-only" />
                        </Spinner>
                    </div>                
                </div>
            )
        }

            
            let view = (
                <div className="container_">                
                <div className="uploadHeader">{/* <h5>Daten Übertragung</h5> */}</div>
                {abrechnungsLine}
                {bankData}
                {InfoBox}              
                {transferBox}                
            </div>
        );
        if (guiData.networkError) {
            view = (
                <div className="container">                
                    <div className="row">
                        <div className="col-md-3"></div> 
                        <div className="col-md-6 cols-sm-12">
                            <div className="alert alert-danger">{guiData.errorMessage}</div>
                        </div> 
                        <div className="col-md-3"></div> 
                    </div>       
                </div>
            );
        }

        return (
            <div>
                {view}                
            </div>
        )
    }
// }

export default FileUploader;
