import React from "react";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from "react-dropzone-uploader";
import Axios from "axios";

import { confirmAlert } from 'react-confirm-alert'; // Import

import {useSelector, useDispatch} from "react-redux";
import { appGuiActions } from "../../store/app-gui-slice";
import { dataActions } from "../../store/data-slice";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

import "./photoBox.css";

const PhotoBox = (props) => {

    const dispatch = useDispatch();
    const guiData = useSelector((state) => state.appgui);
    const claimData = useSelector((state) => state.data);
    
    let imageName = props.image;

    const showPreview = (event) => {
        const photoData = {
            id: props.id,
            title: props.title,
            image: props.image,
            format: props.format,
            description: props.description,
        }
        props.showPreview(photoData);
    }

    const changeView = (event) => {
        const camData= {
            view: "cam",
            id: props.id,
            title: props.title,
            image: props.image,
            format: props.format,
            description: props.description,
        }
        props.changeView(camData);
    }   

    let imageTag = (
        <img onClick={showPreview} className="cardImage center" src={imageName} alt={props.description} />                        
    )

    let fileInfo = false;   

    if (imageName === null || imageName === "undefined" || imageName === undefined) {
        if (props.uploaded && props.metadata !== null) {
            if (props.metadata) {
                fileInfo = true;       
                let modDate = "";
                try {
                    if (props.metadata.lastModifiedDate) {
                        const myDate = Date.parse(props.metadata.lastModifiedDate);
                        modDate = new Intl.DateTimeFormat("de-DE", {
                            year: "numeric",
                            month: "long",
                            day: "2-digit"
                        }).format(myDate);
                    }
                } catch(e) {
                    console.log("PhotoBox(): error while converting date: meta: ", props.metadata);
                }

                imageTag = (
                    <div className="fileInformation">
                        <div className="fileInformationName"><FontAwesomeIcon icon={faCheck} className="checker"/></div>
                        <div className="fileInformationSize">Dateigröße: {props.metadata.size}</div>
                        <div className="fileInformationDate">Datum: {modDate}</div>
                    </div>
                );
            }
        }
    }
    
    if (!imageName && !fileInfo) {
        imageTag = (
            <div onClick={changeView} className="boxDescription">
                {/*props.description*/}
            </div>
        );
    }

    /*Show upload error message*/
    let messages = null;
    if (props.uploadError && props.fileName) {
        messages = (
        <div className="uploadError">Fehler beim Upload der Datei {props.filename}</div>
        )
    }

    // eslint-disable-next-line
    // useEffect(() => {
    //     const video = document.getElementById("videoOverview");
    //     if (video) {
            
    //         //video.srcObject = MediaStream(imageName);//new MediaStream({stream: imageName});
    //     } else {
    //         //console.log("useEffect: did not set the blob");
    //     }
    // });
   
    
    const handleSubmit = (files, allFiles) => {
        
        allFiles.forEach(f => {
            const formData = new FormData();
            formData.append("action", "put");
            formData.append("systemID", props.systemID);
            formData.append("id", props.id);
            formData.append("key", props.assKey);
            formData.append("format", f.meta.type);
            formData.append("title", props.title);
            formData.append("filename", props.fileName);
            formData.append("svp", props.svp);
            formData.append("file", f.file); 
            
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,
                image: f.meta.previewUrl,
                type: props.type,
                fileData: f,
                url: null
            }            
            takePhotoHandler(photoData,"handleSubmit");
            
            // axios.post(props.uploadTarget+"upload.php", formData, {
            Axios.put(props.uploadTarget, formData, {
                headers: { 
                    "X-Requested-Width": "XMLHttpRequest",
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type' : `multipart/form-data; boundary=${f._boundary}`}
                }).then ((res) => {
                    console.log("onSubmit response: ",res);
                })            
            }).catch( (error) => {
                console.log("OnSubmit error: ",error);
                alert(error);
            });        
        }
        
        const onDropFile = (files) => {
            //todo
        }
        
        let fileTypes = "image/*";
        let medium = "Foto";
        let fileMaxSize = (1024 * 1024 * props.fileMaxSizeMultiPhoto);
        if (props.format.startsWith("video/")) {
            fileTypes = "video/*";        
            fileMaxSize = (1024 * 1024 * props.fileMaxSizeMultiVideo);
            medium = "Video";
        } else if (props.type === "doc") {
            fileTypes = "application/*,image/*";
        }        

    const onSkipPhoto = (event) => {
        const photoId = parseInt(event.target.value);
        console.log("onSkipPhoto: id: "+photoId);
        let picturesComplete = true;

        if (photoId >= 0) {
            const standardPhotos = [...claimData.claim.standardPhotos];
            let newPhotos = [];
            standardPhotos.forEach(photo => {
                let currentPhoto = {...photo};
                if (currentPhoto.id === photoId) {
                    currentPhoto.required = false;
                } else if (!currentPhoto.uploaded && currentPhoto.required) {
                    picturesComplete = false;
                }
                newPhotos.push(currentPhoto);
                const payload = {
                    id: currentPhoto.id,
                    required: false,                    
                };
                dispatch(dataActions.updatePhotoRequired(payload));     
            });
            // dispatch(dataActions.updatePhotoList(newPhotos));                        
        }
        dispatch(appGuiActions.setModalVisible(picturesComplete));
        dispatch(appGuiActions.setUploadFinished(picturesComplete));
    };

    
    const onError = (error, errorMessage) => {      
        //1 fileupload error
        dispatch(appGuiActions.setGeneralError(error));
        dispatch(appGuiActions.setErrorMessage(errorMessage));      
      if (error === "1") {
          //spinner
      }
    }
    
    const takePhotoHandler = (photoData, caller) => {   
        console.log("takePhotoHandler called type: "+photoData.type+" called by: "+caller);
        const key = claimData.claim.key;
        dispatch(appGuiActions.setView(photoData.view));
        
        const photoID = photoData.id;
        let uploadingNew = guiData.uploading;     
        let dataChanged = false;   
        if (photoID >= 0) {
            onError(null,"");
            let picturesComplete = true;
            let standardPhotos = [...claimData.claim.standardPhotos];
// console.log("StandardPhotos; ",standardPhotos);
            let newPhotos = [];
            let isUploading = [...guiData.isUploading];
            let updatedPhoto = null;
            standardPhotos.forEach(photo => {
                const curPhoto = {...photo};
                if (curPhoto.id === photoID) {
                    dataChanged = true;
                    // curPhoto.fileData = photoData.fileData;
                    curPhoto.image = photoData.image; 
                        curPhoto.url = photoData.url; 
                    curPhoto.uploaded = photoData.uploaded;
                    // curPhoto.type = photoData.type;
                    let format = "";
                    if (photoData.metadata != null && photoData.metadata.type != null) {
                        format = photoData.metadata.type;
                    }
                    curPhoto.format = format;
                    if (photoData.fileData && photoData.metadata && photoData.metadata) {
                        curPhoto.format = photoData.metadata.type;
                    }
                    try {
                        localStorage.setItem(key+"_up_"+curPhoto.id, photoData.uploaded);       
                        localStorage.setItem(key+"_meta_"+curPhoto.id, JSON.stringify(photoData.metadata));                                                                             
                    } catch (e) {
                        console.log("Local Storage error: ", e);
                        return false;
                    }
                    updatedPhoto = curPhoto;
                }
                if (!curPhoto.uploaded && curPhoto.required) {
                    picturesComplete = false;
                }
                newPhotos.push(curPhoto);
                isUploading[photoID] = false;
                uploadingNew = uploadingNew -1;
                if (uploadingNew < 0) {
                    uploadingNew = 0;
                }                
            });
            dispatch(appGuiActions.setGeneralError(false));
            dispatch(appGuiActions.setErrorMessage(null));
            if (dataChanged){               
                //dispatch(dataActions.updatePhotoList(newPhotos));                
            }
            dispatch(dataActions.updatePhoto(updatedPhoto));                
            dispatch(appGuiActions.setUploadFinished(picturesComplete));
            dispatch(appGuiActions.setModalVisible(picturesComplete));
            dispatch(appGuiActions.setUploading(uploadingNew));
            dispatch(appGuiActions.setIsUploading(isUploading));
        }        
    }


    const onDeletePhoto = (event) => {        
        const photoData = {
            id: props.id,
            title: props.title,
            image: props.image,
            description: props.description,
        };
        const svpNumber = claimData.claim.contractData.svp;//contractData.gutachten_nr_formatiert;
        
        confirmAlert({

            title: "Bild wirklich löschen",
            message: "Sind Sie sicher, dass Sie das Bild für '"+photoData.title+"' löschen wollen?",
            buttons: [                
                {
                    label: 'Nein',
                    onClick: () => console.log("do not delete")
                }, {
                label: 'Ja',
                onClick: () => {
                    const standardPhotos = [...claimData.claim.standardPhotos];
                    let newStandardPhotos = [];
                    standardPhotos.forEach(photo => {
                        let currentPhoto = {...photo};
                        if (currentPhoto.id === photoData.id) {     
                            let format = "";
                            if (currentPhoto.metadata != null && currentPhoto.metadata.type != null) {
                                format = currentPhoto.metadata.type;
                            }
                       
                            const body = new FormData()
                            body.append("action", "del");
                            body.append("id", currentPhoto.id);
                            body.append("ftype", format);
                            body.append("key", claimData.claim.key);
                            body.append("svp", svpNumber);
                            body.append("filename", currentPhoto.fileName);
        
                            const callUrl = guiData.systemData.uploadTarget;
        
                            Axios.post(callUrl+"delete", body).then(res => {
                                const data = res.data;        
                                if (data.code === 200) {
                                    localStorage.removeItem(data.key+"_up_"+data.pid);                   
                                    localStorage.removeItem(data.key+"_meta_"+data.pid);                                    

                                    if (photo.required) {
                                        dispatch(appGuiActions.setUploadFinished(false));
                                    }
                                }
                            }).catch(
                                (error) => {
                                    if (error.response) {
                                        console.log(error.response.data);
                                        console.log(error.response.status);
                                        console.log(error.response.header);
                                    } else if (error.request) {
                                        console.log(error.request);
                                    } else {
                                        console.log("Error", error.message);
                                    }                           
                                }
                            );
                            currentPhoto.uploaded = false;
                            currentPhoto.metadata = null;
                            currentPhoto.fileData = null;
                            currentPhoto.image = null;   
                            const payload = {
                                id: currentPhoto.id,
                                uploaded: false,
                                metadata: null,
                                fileData: null,
                                image: null
                            };
                            dispatch(dataActions.updatePhotoStatus(payload));                                      
                        }
                        newStandardPhotos.push(currentPhoto);
                    });
                    // dispatch(dataActions.updatePhotoList(newStandardPhotos));                    
                }
              }
            ]
          });
    }
    
    const onUploading = (photoId)  => {
        let uploading = guiData.uploading;
        let isUploading = [...guiData.isUploading];
console.log("PB: IsUploading: ",isUploading);
        uploading = uploading + 1;
        isUploading[photoId] = true;

        console.log("onUploading: ",isUploading[photoId]);
        dispatch(appGuiActions.setUploading(uploading));
        dispatch(appGuiActions.setIsUploading(isUploading));        
    };
     
    const handleChangeStatus = ({ meta, file }, status) => { 
        // console.log("-- handleChangeStatus: ", status);        
        if (status === "done") {
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,  
                // type: props.type,
                type: props.phototype,
                image: meta.previewUrl,
                fileData: file,
                uploaded: true,
                uploadError: false,
                metadata: meta,
                url: null
            }            
            takePhotoHandler(photoData, "handleChangeStatus to done");
        } else if (status === "error_file_size") {
            onError(props.id, "das Bild überschreitet die maximale Größe");
        } else if (status === "preparing") {
            onUploading(props.id);
        } else if (status === "exception_upload" || status === "aborted") {
            const photoData = {
                id: props.id,
                format: props.format,
                title: props.title,                
                fileName: meta.name,
                image: null,
                fileData: null,
                uploadError: true
            }            
            takePhotoHandler(photoData,"handleChangeStatus to exception or aborted");
        }
    }
    
    const getUploadParams = ({ file, meta }) => { 
        console.log("-- getUploadParams");
        const body = new FormData()
        body.append("action", "put");
        body.append("systemID", props.systemID);
        body.append("id", props.id);
        body.append("key", props.assKey);
        body.append("format", meta.type);
        body.append("title", props.title);
        body.append("filename", props.fileName);
        body.append("svp", props.contractData.svp);
        body.append("file", file.file); 
        body.append('fileField', file)     

        return { 
            url: props.uploadTarget+"put" , body
        } 
    }
        
    
    let dropBox = (
        <span id={`dropbox_${props.id}`}>
            {messages}
            <Dropzone 
                onDrop={onDropFile}
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                resizeWidth={140}
                maxFiles={1}
                multiple={false}
                maxSizeBytes={fileMaxSize}
                accept={fileTypes}
                inputContent=""
                autoUpload={true}
                />                           
        </span>
    );
    
    
    let boxAction = null;
    let messageText = (
        "Ziehen Sie die Datei hier hinein oder machen Sie ein Foto"
        );

    if (props.format.startsWith("video/")) {
        messageText = (
            "Ziehen Sie die Datei hier hinein oder machen Sie ein Video"
            );
    }
    
    if (props.image != null || fileInfo) {
        dropBox = imageTag;        
        messageText = (
            <div className="deletePhotoBoxPic" onClick={onDeletePhoto}>
            {medium} löschen
        </div>
        )
        boxAction = onDeletePhoto;
    }
    
    let className = "";    
    let checkCardBoxFooter = "";
    let checkCardBoxTitle = "";
    let checkCardBoxHeight = "";
    let minHeight= 200;
    if (props.className) {
        className = props.className;
        checkCardBoxFooter = props.className+"Footer";
        checkCardBoxTitle = props.className+"Title";
        checkCardBoxHeight = props.className+"Height";
        minHeight = 100;
    }
    
    let backgroundStyle = null;
    if (props.icon !== null && props.metadata === null) {
        backgroundStyle = {            
            backgroundImage: `url(${process.env.PUBLIC_URL+props.icon})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            maxHeight: minHeight+"px",
        };
    }

    let errorBox = null;
    if (props.generalError && props.generalError === props.id) {
        errorBox = <div className="alert alert-danger">{props.errorMessage}</div>
    }

    let skipUploadItem = (
        <div>
        {props.required && props.skipbtn && !props.uploaded && <button className="btn my-btn-block btn-warning" value={props.id} onClick={onSkipPhoto}>nicht fotografierbar</button>}
        </div>
    );

    return (                        
        <div className={className}>
            <div className="card gridCard " id={"box_"+props.id}>
                <div className="card-header pictureCardHeader">
                    <h5 className={"card-title photoCardTitle "+checkCardBoxTitle}>{props.title}</h5> 
                </div>
                <div className={"card-body pictureCardBody "+checkCardBoxHeight+" "+ checkCardBoxFooter+ " "+((!props.uploaded && props.required) ? "photoNotComplete" : "photoComplete")} style={backgroundStyle}>
                    {dropBox}
                </div> 
                <div className={"card-footer pictureCardFooter "+checkCardBoxFooter} onClick={boxAction}>
                    {messageText}
                </div>
                {skipUploadItem}               
            </div> 
            {errorBox}                                  
        </div>
    );
}

export default PhotoBox;