import React from "react";
import {useSelector} from "react-redux";

import PhotoList from "../components/photo/photoList";
import Controls from "./controls";
//import ClickableCar from "../ClickableCar/ClickableCar";

import "./layoutMain.css";

const LayoutMain = (props) => {    
    const guiData = useSelector((state) => state.appgui);

    let photoBoxes = (
        <div>
            <PhotoList 
                type="std"
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                className={null}                
            />    
            {/* <div>
                <div className="photoListBoxHeader">&nbsp;</div>
                <ClickableCar 
                    uploadTarget={props.uploadTarget}
                    takePartPhotoHandler={props.takePartPhotoHandler}
                    changePartState={props.changePartState}
                    isUploading={props.isUploading}
                    systemID={props.systemID}
                    assKey={props.assKey}         
                    svp={props.svp}           
                    partList={props.partList}
                />    
            </div> */}
            <PhotoList                 
                type="dam"
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
            
                className={null}                 
            />
            <PhotoList 
                type="doc"      
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                className={null}              
            />            
        </div>
    );

    if (guiData.jobCanceled || guiData.transferFinished || guiData.appInitialized === false) {
        photoBoxes = null;
    }
    return (        
        <div className="container_" id="controlsbox">        
            {photoBoxes}                  
            <Controls                                                                 
                showPreview={props.showPreview}
                openPhoto={props.openPhoto}                                   
            />            
        </div>
    );
}

export default LayoutMain;