import { createSlice, getDefaultMiddleware } from "@reduxjs/toolkit";

const photoRequired = true;
const damageIcon =  "/images/icons/detail2.png";
const documentIcon = "/images/icons/dokumente.png";

let contractKey = null;//"FQAIUFJK";

let myState = -1;
const carType = localStorage.getItem(contractKey+"_cartype");        
if (carType) {
    myState = parseInt(carType,10);
}    

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

const initialDataState = {            
    claim : {
        svp: null,        
        schadenausweitung: "N",
        key: contractKey,
        chargeType: "-2",//abrechnungswunschauswahl
        vorSchaden: -1,//abrechnungswunschauswahl
        damageIcon: damageIcon,
        documentIcon: documentIcon,
        vorschadenDetail: -1,
        vorschadenAmount: 0.0,
        choose_copart: 0,
        haftpflicht: 'N',
        fzg_aufbauart: null,
        fzg_fahrzeugart: null,
        contractData: {
            vu_schadennummer: null,        
            timestamp_auftrag: null,
            vn_anrede: null,
            vn_nachname: null,
            as_anrede: null,
            ad_vorname: null,
            as_nachname: null,    
            ansichten: null,                    
        },
        customer: {
            firstname: "",
            lastname: "",
            mail: "",
            phone: "",
            bankData: {
                firstname: "",
                lastname: "",                
                iban: "",                
            },
        },    
        maxPhotoId: 13,
        standardPhotos: [
            {id: 1, type: 0,  fileData: null, uploaded: false, metadata: null, format: "video/webm",required: false, fileName: "video", title: "Video rund um das Fahrzeug, max. 1 Minute", image: null, icon: "/images/icons/aufnahme.png", description: "Video rechts um das Fahrzeug, in gleichbleibendem Abstand", info: ""},            
            {id: 2, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "fhzgschein_image", title: "Fahrzeugschein", image: null, icon: "/images/icons/Zulassung.png", description: "Fahrzeugschein", info: "wie fotografiere ich den schein"},
            {id: 3, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "tacho_image", title: "Kilometerstand - bei laufendem Motor", image: null, icon: "/images/icons/tacho.png", description: "Tacho mit gut lesbarem Kilometer Stand", info: "darauf achten dass der blitz aus ist"},
            {id: 4, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "front_image", title: "Fahrzeug von vorne - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_front.png", description: "Fahrzeug Front", info: ""},
            {id: 5, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "rechts_image", title: "Fahrzeug von rechts - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_rechteSeite.png", description: "rechte Fahrzeug Seite", info: ""},
            {id: 6, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "heck_image", title: "Fahrzeug von hinten - Abstand ca. 2,5m", image: null, icon: "/images/icons/Pkw1_back.png", description: "Fahrzeug Heck", info: ""},
            {id: 7, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "links_image", title: "Fahrzeug von links - Abstand ca. 2 - 3,5m", image: null, icon: "/images/icons/Pkw1_linkeSeite.png", description: "linke Fahrzeug Seite", info: ""},
            {id: 8, type: 0,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "fahrzeugreifen_image", title: "Fahrzeug Reifen - Abstand ca. 1,5 - 2m", image: null, icon: "/images/icons/reifen.png", description: "Fahrzeug Reifen", info: ""},                
            {id: 9, type: 2,  fileData: null, uploaded: false, metadata: null, format: "doc",       required: false, fileName: "document1_image", title: "Sonstige Dokumente z.B.: Kostenvoranschlag", image: null, icon: documentIcon, description: "", info: ""},
            {id: 10, type: 1,  fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "gesamtschaden_image", title: "Schaden Übersicht", image: null, icon: "/images/icons/detail1.png", description: "", info: ""},
            {id: 11, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "detail1_image", title: "Schaden Foto - im Detail 1 Abstand 1 - 2m", image: null, icon: damageIcon, description: "", info: ""},
            {id: 12, type: 1, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: photoRequired, fileName: "detail2_image", title: "Schaden Foto - im Detail 2 Abstand 0,5 - 1m", image: null, icon: damageIcon, description: "", info: ""},
            {id: 13, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardfront_image", title: "Bank Karte Vorder-Seite", image: null, icon: "/images/icons/credit_card_front.png", description: "", info: ""},
            {id: 14, type: 3, fileData: null, uploaded: false, metadata: null, format: "img/jpeg",  required: false, fileName: "bankcardback_image", title: "Bank Karte Rück-Seite", image: null, icon: "/images/icons/credit_card_back.png", description: "", info: ""},                
            {id: 15, type: 4, fileData: null, uploaded: false, metadata: null, format: "doc",  required: false, fileName: "vorschaden1_image", title: "Foto des Schadens oder des Kostenvoranschlags", image: null, icon: documentIcon, description: "Hatten Sie an Ihrem Fahrzeug schon einen Vorschaden?", info: ""},                
            {id: 16, type: 2, fileData: null, uploaded: false, metadata: null, format: "doc",  required: photoRequired, fileName: "wildunfallbescheinigung_image", title: "Bescheinigung des Wildunfalles", image: null, icon: "/images/icons/wildunfallbescheinigung.png", description: "", info: ""},                
        ],
        hasDamagePhotos: false,
        hasDocuments: false,
        standardPhotosTexts: null,
        isUploading:[false,false,false,false,false,false,false,false,false,false,false,false,false,false, false, false, false, false],             
        currentPhoto: null, 
        missingImageList: [],  
        
    },
    injurance : {
        injuranceName: null,
        injurenceLogo: null,
    },
    injurenceLogo: null,
    carType: myState,
}

const dataSlice = createSlice({
    name: "data",
    initialState: initialDataState,
    reducers: {
        setHasDamagePhotos(state, action) {
            state.claim.hasDamagePhotos = action.payload;
        },
        setHasDocuments(state, action) {
            state.claim.hasDocuments = action.payload;
        },
        dataReceived(state, action) {
            
        },
        setPhotoList(state, action) {              
            state.claim.standardPhotos = action.payload;            
        },
        addPhoto(state, action) {
            const newPhoto = action.payload;
            if (newPhoto) {
                state.claim.standardPhotos.push(newPhoto);
            }
        },
        updatePhotoStatus(state, action) {
            const id = action.payload.id;
        
            const index = state.claim.standardPhotos.findIndex((item) => item.id = id);
            if (index >= 0) {
                const uploaded = action.payload.uploaded;
                const metadata = action.payload.metadata;
                const fileData = action.payload.fileData;
                const image = action.payload.image;
                state.claim.standardPhotos[index].uploaded = uploaded;
                state.claim.standardPhotos[index].metadata = metadata;
                state.claim.standardPhotos[index].fileData = fileData;
                state.claim.standardPhotos[index].image = image;
            }            
        },
        updatePhotoRequired(state, action){
            const id = action.payload.id;
            const required = action.payload.required;                
            const index = state.claim.standardPhotos.findIndex((item) => item.id = id);
            if (index >= 0) {
                state.claim.standardPhotos[index].uploaded = required;
            }
        },
        updatePhoto (state, action) {
            const newPhoto = action.payload;
            console.log("dispath update photo: "+newPhoto.key);
            let newPhotos = [];
            state.claim.standardPhotos.forEach(photo => {
                const curPhoto = {...photo};
                if (curPhoto.id === newPhoto.id) {
                    console.log("dispatch:updatePhoto bePhoto set");
                    newPhotos.push(newPhoto);
                } else {
                    newPhotos.push(curPhoto);
                }
            });            
            state.claim.standardPhotos = newPhotos;
        },
        setMissingImageList(state, action) {
            state.claim.missingImageList = action.payload;
        }, 
        setStandardPhotosText(state, action) {
            state.claim.standardPhotosTexts = action.payload;
        }, 
        setKey(state, action) {
            state.claim.key = action.payload;
        },
        setMaxPhotoId(state, action) {
            state.claim.maxPhotoId = action.payload;
        },        
        setChargeType(state, action) {
            state.claim.chargeType = action.payload;
        },
        setAnsichten(state, action) {
            state.claim.contractData.ansichten = action.payload;
        },
        resetContractData(state) {
            state.claim.contractData = null;                
        },
        setBankData(state, action) {            
            state.claim.customer.bankData = action.payload;            
        },
        setContractData(state, action) {
            const contractData = action.payload;
            state.claim.vu_schadennummer = contractData.vu_schadennummer;
            state.claim.svp = contractData.svp;
            state.claim.auszahlung_wbw_netto = contractData.auszahlung_wbw_netto;
            state.claim.contractData = contractData;                
            state.injurance.injuranceName = contractData.vu_name;                
            state.injurance.injurenceLogo = contractData.vu_logo;
            state.claim.haftpflicht = contractData.haftpflicht;
            state.claim.schadenausweitung = contractData.schadenausweitung;
            state.claim.customer.mail = contractData.vn_email;
            state.claim.fzg_aufbauart = contractData.fzg_aufbauart;
            state.claim.fzg_fahrzeugart = contractData.fzg_fahrzeugart;
        },
        setError(state, action) {
            console.log(action.payload.title);
        },
        setVorschaden(state, action) {
            state.claim.vorSchaden = action.payload;            
            // console.log("dispatch Vorschaden: "+state.claim.vorSchaden);
        },
        setVorschadenDetail(state, action) {
            state.claim.vorschadenDetail = action.payload;            
            // console.log("dispatch VorschadenDetail: "+state.claim.vorschadenDetail);
        },
        setVorschadenAmount(state, action) {
            state.claim.vorschadenAmount = action.payload;
            // console.log("dispatch VorschadenAmount: "+state.claim.vorschadenAmount);
        },
    }
});

export const dataActions = dataSlice.actions;
export default dataSlice.reducer;