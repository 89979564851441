import React from "react";
import {useSelector} from "react-redux";

import LayoutMain from "./layoutMain";
import Header from "./header/header";
import "./layout.css";

//import Jitsi from "../Jitsi/jitsi.component";
//import Jitsi from 'react-jitsi'

const getErrorMessage = (error) => {    
    switch(error) {       
        case '10001':
            return 'Diese Datei existiert bereits';
        case '10002':
            return "Diese Datei konnte nicht angelegt werden: ";
        case '10003':
            return "In Datei konnte nicht geschrieben werden: ";
        // Datenbank
        case '20001':
            return "Fehler beim Schreiben in die Datenbank: ";
        case '20002':
            return "Dieses Gutachten existiert nicht: ";
        case '20003':
            return "Die Liste der Gutachten kann nicht erstellt werden.";
        case '20004':
            return "Benutzername und/oder Passwort falsch.";
        case '20005':
            return "Der Gutachtenschlüssel konnte nicht ermittelt werden";
        case '20006':
            return "Kein Gutachten für diesen Benutzer verfügbar.";
        // XML
        case '30001':
            return "Fehler beim Einlesen der XML Datei.";
        case '30002':
            return "Die Datei wurde beim Übertragen beschädigt.";
        // CarPic
        case '40001':
            return 'Schaden-Typ des Auftrags ist nicht Privathaftpflicht.';
        case '40002':
            return 'Für diesen Auftrag wurden keine Fotos angefragt.';
        case '40003':
            return 'Ungültiger Schlüssel.';
        case '40004':
            return 'Zu diesem Vorgang wurden bereits Bilder hochgeladen.';
        case '40005':
            return 'Das Ablaufdatum wurde überschritten.';
        case '40006':
            return 'Der Auftrag wurde bereits abgeschlossen.';
        default: return error;
    }
}
const Layout = (props) => {

    // const claimData = useSelector((state) => state.data);    
    const guiData = useSelector((state) => state.appgui);

        let logo = process.env.PUBLIC_URL+"/images/krug_logo_transparent.png"
        let alttext = "SV-Krug GmbH Logo";
        let titleText = "";        
        
        let layoutMain = (
            <div>
                <Header />                
                <LayoutMain 
                    changeView={props.changeView}
                    openPhoto={props.openPhoto}
                    showPreview={props.showPreview}
                    onTransferFinished={props.onTransferFinished}
                /> 
            </div>
        );
    
        if (guiData.networkError) {
            layoutMain = (
            <div className="alert alert-warning errorMessageBox">{getErrorMessage(guiData.errorMessage)}</div>
            );
        }
         
        let viewData = (
            <div className="container">
                <div className="headerLogoBox">
                    <p className="headerLogoText">Im Auftrag von</p>   
                    <p className="vuName">{titleText}</p>             
                    <img className="App-logo-krug" src={logo} alt={alttext}></img>
                </div>
                 <div className="layoutHeader">&nbsp;</div>
                    <div className="container mainLayout">            
                        {layoutMain}
                    </div>
                <div className="layoutFooter">&nbsp;</div>
    
                <div className="appFooter">
                    <div>&nbsp;</div>
                    <div className="appFooterLogoBox">
                        <p className="appFooterHead">Ihr Ansprechpartner:</p>
                        <div >                    
                            <a href="https://www.sv-krug.de" target="_blank" rel="noopener noreferrer"><img  className="appFooterLogo" src={process.env.PUBLIC_URL+"/images/krug_logo_transparent.png"} alt="SV-Krug GmbH Logo"></img></a>
                        </div>
                        <p className="appFooterFoot"><a href="tel:+496838986180" className="tapiLink">Telefon: 06838 98618‐0</a></p>                    
                    </div>
                    <div>&nbsp;</div>                
                </div>
                <hr className="endLine" />
                <div className="endLineControls">                
                    <div></div>
                    <a href="https://sv-krug.de/de/Impressum" target="_blank" rel="noopener noreferrer" className="mainInfoButton" >Impressum</a>
                    <div></div>
                    <a href="https://sv-krug.de/de/Datenschutz" target="_blank" rel="noopener noreferrer" className="mainInfoButton">Datenschutzerklärung</a>
                    <div></div>
                </div>
                <div className="layoutFooter">&nbsp;</div>
            </div>        
        )
    
        if (guiData.view && guiData.view === "cam") {
            viewData = (
                <div className="container mainLayout">            
                    <Header />               
                    CAMERA
                </div>
            )
        }    
        return viewData;    
    };        

export default Layout;